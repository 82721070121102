import React, { useContext } from 'react'
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge,
} from 'reactstrap'
import PerfectScrollbar from 'react-perfect-scrollbar'
import * as Icon from 'react-feather'
import { FaCircle } from 'react-icons/fa'
import { history } from '../../../history'
import { withContext } from '../../../utils/helper'
import { AppContext } from '../../../context/AppContext'
import AuthImage from '../../../components/image/Image'
import ajaxProfile from '../../../views/pages/Profile/ajax'
import moment from 'moment'
import { black, white } from '../../../components/styles/styles'
import FetchHandler from '../../../components/state/FetchHandler'
import { Image } from '../../../components/styles/components'
import { dummypic } from '../../../utils/apihost'

const UserDropdown = (props) => {
  const context = useContext(AppContext)
  return (
    <DropdownMenu right>
      <DropdownItem
        tag="a"
        href="#"
        className="mobile-menu-list"
        onClick={(e) => {
          e.preventDefault()
          history.push('/request')
        }}
      >
        <Icon.FileText size={14} className="mr-50" />
        <span className="align-middle">Pengajuan</span>
      </DropdownItem>
      <DropdownItem
        tag="a"
        href="#"
        className="mobile-menu-list"
        onClick={(e) => {
          e.preventDefault()
          history.push('/document/manage')
        }}
      >
        <Icon.Folder size={14} className="mr-50" />
        <span className="align-middle">File Manager</span>
      </DropdownItem>
      <DropdownItem
        tag="a"
        href="#"
        className="mobile-menu-list"
        onClick={(e) => {
          e.preventDefault()
          history.push('/approval')
        }}
      >
        <Icon.Edit size={14} className="mr-50" />
        <span className="align-middle">Approval</span>
      </DropdownItem>
      <DropdownItem
        tag="a"
        href="#"
        onClick={(e) => {
          e.preventDefault()
          history.push('/profile')
        }}
      >
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">Profile</span>
      </DropdownItem>
      <DropdownItem
        tag="a"
        href="#"
        onClick={(e) => {
          e.preventDefault()
          context.update('showEsign', true)
        }}
      >
        <Icon.CheckSquare size={14} className="mr-50" />
        <span className="align-middle">E-Sign</span>
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem tag="a" href="#" onClick={(e) => context.logout()}>
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  )
}

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    suggestions: [],
    data: [],
    unreadids: [],
    open: false,
    loading: false,
  }

  componentDidMount() {
    this.fetchData()
  }
  componentDidUpdate(prevProps, prevState) {
    const { open, unreadids } = this.state
    if (!prevState.open && open && unreadids.length > 0) this.read()
  }

  fetchData = async () => {
    const { token } = this.props.context.state
    const res = await ajaxProfile.getNotifications(1, 10, token)
    if (!res.success) {
      this.setState({ loading: false, errordata: true })
      return false
    }
    let data = res.data.notifications
    let unreadids = []
    data.map((g) => {
      if (!g.read_time) {
        unreadids.push(g.notification_id)
      }
      return g
    })
    this.setState({ loading: false, data, unreadids })
  }

  read = async () => {
    const { unreadids } = this.state
    const { token } = this.props.context.state
    const res = await ajaxProfile.readNotifications(unreadids, token)
    if (!res.success) {
      this.setState({ loading: false, errordata: true })
      return false
    }
    this.setState({ loading: false, unreadids: [] })
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch,
    })
  }

  render() {
    const { data, unreadids, open, loading, errordata } = this.state
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item"
        >
          <DropdownToggle
            tag="a"
            className="nav-link nav-link-label"
            onClick={() => this.setState({ open: !open })}
          >
            <Icon.Bell size={21} style={{ color: white }} />
            <Badge pill color="primary" className="badge-up">
              {unreadids?.length}
            </Badge>
          </DropdownToggle>
          <DropdownMenu tag="ul" right className="dropdown-menu-media">
            <PerfectScrollbar
              className="media-list overflow-hidden position-relative"
              options={{
                wheelPropagation: false,
              }}
            >
              <FetchHandler
                loading={loading}
                error={errordata}
                refetch={() => this.fetchData()}
                content={
                  <>
                    {data?.map((g, i) => (
                      <div
                        key={i}
                        className="d-flex justify-content-between"
                        onClick={() => {
                          if (g.action == 'approval_detail')
                            history.push('/approval/' + g.form_request_id)
                          else if (g.action == 'request_detail')
                            history.push('/request/' + g.form_request_id)
                        }}
                      >
                        <Media className="d-flex align-items-start">
                          <Media left href="#">
                            <FaCircle
                              size={16}
                              color={black}
                              style={{ opacity: g.read_time ? 0.2 : 1 }}
                            />
                          </Media>
                          <Media body>
                            <Media heading className="media-heading" tag="h6">
                              {g.content}
                            </Media>
                            <small>
                              {moment(g.created_at).format(
                                'DD MMM YYYY, HH:mm',
                              )}
                            </small>
                          </Media>
                        </Media>
                      </div>
                    ))}
                  </>
                }
              />
            </PerfectScrollbar>
            <li
              className="dropdown-menu-footer"
              onClick={() => history.push('/notifications')}
            >
              <DropdownItem tag="a" className="p-1 text-center">
                <span className="align-middle">Lihat Semua</span>
              </DropdownItem>
            </li>
          </DropdownMenu>
        </UncontrolledDropdown>
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span
                className="user-name text-bold-600"
                style={{ color: white }}
              >
                {`${this.props.context.state?.user?.first_name} 
								${this.props.context.state?.user?.last_name}`}
              </span>
              <span className="user-status" style={{ color: white }}>
                Available
              </span>
            </div>
            <span data-tour="user">
              {this.props.context.state?.user?.avatar ? (
                <AuthImage
                  src={this.props.context.state?.user?.avatar}
                  className="round"
                  height="40"
                  width="40"
                  alt="avatar"
                />
              ) : (
                <Image
                  src={dummypic}
                  className="round"
                  height="40"
                  width="40"
                  alt="avatar"
                />
              )}
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    )
  }
}
export default withContext(NavbarUser)
